<template>
  <base-page-layout title="Clientes">
    <v-card class="mt-3" flat>
      <v-card-title class="mb-6">
        <v-text-field v-model="search" class="pt-0 mt-0" width="200" hide-details single-line="" placeholder="Busca" append-icon="search" />
        <v-spacer />
        <v-btn color="primary" class="mr-4" outlined @click="importCsvDialog = true"
          ><v-icon class="mr-1">mdi-account-remove</v-icon> Importar .CSV - Blocklist</v-btn
        >
        <v-btn color="primary" @click="confirmationDialog = true"><v-icon class="mr-1">sync</v-icon> Sincronizar Clientes</v-btn>
      </v-card-title>
      <data-table-pagination :search="search" :headers="headers" :query="query" queryName="platformUsers" :account-id="accountId" :query-params="queryParams">
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.name }}</td>
            <td>{{ item.erp_id ? item.erp_id : '-' }}</td>
            <td>{{ item.document_no }}</td>
            <td>{{ item.sync_error ? item.sync_error : '-' }}</td>
            <td>
              <v-icon v-if="item.confirmation_sent">check_circle_outline</v-icon>
              <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
            </td>
            <td>{{ item.confirmation_sent_at ? formatDate(item.confirmation_sent_at) : '-' }}</td>
            <td>{{ formatDate(item.sync_at) }}</td>
            <td>{{ item.sync_last_retry ? formatDate(item.sync_last_retry) : '-' }}</td>
            <td class="text-xs-center">
              <v-switch v-model="item.sync" class="mr-auto" @change="changeSync($event, item.document_no)" />
            </td>
            <td>
              <v-menu bottom transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined small color="primary" dark v-bind="attrs" v-on="on"> Detalhes <v-icon class="ml-2">mdi-menu-down</v-icon> </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                  <v-list-item link @click="openGoogleCloudLogs(item.document_no)">
                    <v-list-item-title><v-icon class="mb-2 mr-2">mdi-google-cloud</v-icon> Log GCP</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="openOptionsModal(item)">
                    <v-list-item-title><v-icon class="mb-2 mr-2">mdi-account</v-icon> Opções</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </data-table-pagination>

      <confirmation-dialog
        :dialog="confirmationDialog"
        text="TODOS os clientes serão reenviados para fila para sincronização. Esta ação não pode ser revertida."
        @cancel="confirmationDialog = false"
        @proceed="massiveSync"
      />
      <options-modal
        v-if="selectedUser"
        :dialog="optionsDialog"
        :account-id="accountId"
        :user="selectedUser"
        @close="closeOptionsModal"
        @closeAndUpdate="refresh"
      />
      <users-import-csv :dialog="importCsvDialog" :account-id="accountId" @close="importCsvDialog = false" />
    </v-card>
  </base-page-layout>
</template>

<script>
import { QUERY_GET_PLATFORM_USERS, MUTATION_UPDATE_USER_PLATFORM_SYNC, MUTATION_MASSIVE_SYNC_PLATFORM_USERS } from '@/modules/accounts/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout'),
    DataTablePagination: () => import('@/components/tables/DataTablePagination'),
    OptionsModal: () => import('@/modules/accounts/components/platform/OptionsModal'),
    UsersImportCsv: () => import('@/modules/accounts/components/platform/UsersImportCsv'),
    ConfirmationDialog: () => import('@/components/base/ConfirmationDialog')
  },
  data: () => ({
    accountId: '',
    search: '',
    confirmationDialog: false,
    optionsDialog: false,
    importCsvDialog: false,
    selectedUser: null
  }),
  computed: {
    query() {
      return QUERY_GET_PLATFORM_USERS
    },
    queryParams() {
      return { id: this.accountId }
    },
    headers() {
      return [
        {
          text: 'Usuário',
          value: 'name',
          width: '12%'
        },
        {
          text: 'ERP ID',
          value: 'erp_id',
          width: '8%'
        },
        {
          text: 'Nº Documento',
          value: 'document_no',
          width: '12%'
        },
        {
          text: 'Erro',
          value: 'sync_error',
          width: '12%'
        },
        {
          text: 'Confirmação Enviada',
          value: 'confirmation_sent',
          width: '10%'
        },
        {
          text: 'Envio de Confirmação',
          value: 'confirmation_sent_at',
          width: '10%'
        },
        {
          text: 'Data de Sincronização',
          value: 'sync_at',
          width: '10%'
        },
        {
          text: 'Último Retry',
          value: 'sync_last_retry',
          width: '10%'
        },
        {
          text: 'Sync',
          value: 'sync',
          width: '5%'
        },
        {
          value: 'details',
          width: '12%',
          sortable: false
        }
      ]
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    async changeSync(e, doc) {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_USER_PLATFORM_SYNC,
          variables: {
            id: this.accountId,
            sync: e,
            doc: doc
          }
        })
        this.$snackbar({ message: 'Sincronização do usuário atualizada', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao editar usuário', snackbarColor: '#F44336' })
      }
    },
    async massiveSync() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_MASSIVE_SYNC_PLATFORM_USERS,
          variables: {
            id: this.accountId
          }
        })
        this.$snackbar({ message: 'Usuários enviados para fila de sincronização', snackbarColor: '#2E7D32' })

        setTimeout(() => {
          this.$router.go()
        }, 300)
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao sincronizar usuários', snackbarColor: '#F44336' })
      }
    },
    openGoogleCloudLogs(docNum) {
      window.open(
        `https://console.cloud.google.com/logs/query;query=resource.type%3D%22gce_instance%22%20resource.labels.instance_id%3D%221032178135888642472%22%20${docNum};cursorTimestamp=2021-06-30T20:22:09.734169638Z?project=tranquil-rite-247716`,
        '_blank'
      )
    },
    formatDate(date) {
      if (date) return this.$moment(date).format('DD/MM/YYYY - HH:MM:ss')
      return 'Sem registro de sincronização'
    },
    openOptionsModal(user) {
      this.optionsDialog = true
      this.selectedUser = user
    },
    closeOptionsModal() {
      this.optionsDialog = false
      this.selectedUser = null
    },
    refresh() {
      this.optionsDialog = false
      this.$router.go()
    }
  }
}
</script>
